import React from 'react';
import AboutUs from './AboutUs';

function About() {
    return (
        <div style={{ marginTop: '120px', minHeight: 'calc(100vh - 120px)', padding: '20px' }}>
            <AboutUs/>
        </div>
    )

}

export default About;

import React, { useState, useEffect } from 'react';
import image1 from '../assets/image1.png';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import image4 from '../assets/image4.jpg';

const Slider = () => {
  const images = [image1, image2, image3, image4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for reverse

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex + direction;

      if (newIndex === images.length) {
        setDirection(-1);
        newIndex = images.length - 2;
      }

      if (newIndex === -1) {
        setDirection(1);
        newIndex = 1;
      }

      return newIndex;
    });
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);
    return () => clearInterval(interval);
  }, [direction, currentIndex]);

  // Slider container style
  const sliderStyle = {
    width: '100%',
    height: '75vh', // Set height of the slider to 50% of the viewport height
    overflow: 'hidden',
    position: 'relative',
  };

  // Wrapper for slides
  const slideWrapperStyle = {
    display: 'flex',
    transition: 'transform 1s ease-in-out',
    transform: `translateX(-${currentIndex * 100}%)`,
  };

  // Individual slide style
  const slideStyle = {
    width: '100%', // Full width for each slide
    height: '100%', // Make sure it takes up the full height of the container
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  // Image style with contain property
  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain', // This will make sure the image is fully contained without cropping
  };

  return (
    <div style={sliderStyle}>
      <div style={slideWrapperStyle}>
        {images.map((image, index) => (
          <div key={index} style={slideStyle}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={imgStyle}
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;

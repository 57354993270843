import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from './Slider';
import Footer from './Footer'; // Import the Footer component

function Home() {
  const location = useLocation();
  const bottomRef = useRef(null);
  const descriptionRef = useRef(null);

  // State for window width to handle responsiveness
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    document.title = "Clear Horizons - Home";
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll to the bottom if `scrollToBottom` state is passed
  useEffect(() => {
    if (location.state?.scrollToBottom) {
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [location.state]);

  // Slider styles (dynamic height for smaller screens)
  const sliderStyle = {
    width: '100%',
    height: windowWidth <= 768 ? '40vh' : '50vh', // Reduced height for small screens
    overflow: 'hidden',
    position: 'relative',
  };

  // Description styles with dynamic marginTop
  const descriptionStyle = {
    marginTop: windowWidth <= 768 ? '30px' : '50px', // Increased margin-top to add more space between slider and text
    textAlign: 'center',
    fontSize: '18px',
    color: '#555',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const descriptionTextStyle = {
    fontSize: windowWidth <= 768 ? '18px' : '22px', // Adjust text size for mobile
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  };

  return (
    <div style={{ marginTop: '120px', minHeight: 'calc(100vh - 120px)', padding: '0' }}>
      <Slider />

      {/* Description Section */}
      <div ref={descriptionRef} style={descriptionStyle}>
        <p style={descriptionTextStyle}>
          Empowering Blind and Visually Impaired Talent!
        </p>
        <p
          style={{
            fontSize: windowWidth <= 768 ? '14px' : '18px', // Smaller font for mobile
            color: '#555',
            textAlign: 'justify',
            marginBottom: '20px',
          }}
        >
          Our platform provides a space for blind and visually impaired individuals to create profiles and showcase their unique skills to potential employers.
        </p>
        <p
          style={{
            fontSize: '16px',
            color: '#777',
            fontStyle: 'italic',
          }}
        >
          Join us in fostering a more inclusive and diverse workforce!
        </p>
      </div>

      {/* Footer Section */}
      <div ref={bottomRef} id="bottom" style={{ width: '100%', marginTop: '50px' }}>
        <Footer /> {/* Render the Footer component */}
      </div>
    </div>
  );
}

export default Home;

import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';  // No need for BrowserRouter here
import Navbar from './components/Navbar';
import Home from './components/Home';  
import About from './components/About';  
import Login from './components/Login';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const [loading, setLoading] = useState(true);  // State to manage loading screen
  const navigate = useNavigate();

  // Show loading screen for 3 seconds, then navigate to home
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);  // Stop loading after 3 seconds
      navigate('/home');  // Navigate to home page
    }, 3000);
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingScreen />  // Display loading screen while loading
      ) : (
        <>
          <Navbar />  {/* Navbar will be visible on all pages */}
          <Routes>
            <Route path="/" element={<Home />} />  {/* Default home page */}
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;

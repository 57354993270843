import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const Login = () => {
  useEffect(() => {
    document.title = "Clear Horizons - Login";
  })
  const [assignedId, setAssignedId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Simulate login logic (Currently, always display incorrect credentials alert)
    setTimeout(() => {
      setLoading(false);
      setError('Incorrect credentials');
    }, 2000);
  };

  // Handle the "Wanna join our mission?" button click
  const handleJoinClick = () => {
    // Navigate to Home and pass the state to trigger scroll
    navigate('/home', { state: { scrollToBottom: true } });
  };

  return (
    <section style={{ backgroundColor: '#f4f7fc', minHeight: '100vh', paddingTop: '120px' }}>
      <div className="container h-100 d-flex justify-content-center align-items-center" style={{ transform: 'scale(0.98)', transformOrigin: 'top center' }}>
        <div className="row w-100 d-flex justify-content-center align-items-center">
          <div className="col-xl-10">
            <div className="card rounded-4 shadow-lg" style={{ overflow: 'hidden', padding: '40px', backgroundColor: '#fff' }}>
              <div className="row g-0 h-100">
                {/* Left Side (Login Form) */}
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="card-body p-md-5 mx-md-4 w-100">
                    <div className="text-center">
                      <img
                        src={'clearHorizonsLogo2.png'}
                        alt="Logo"
                        style={{ width: '200px', height: '200px', marginBottom: '20px' }}
                      />
                    </div>

                    <form onSubmit={handleSubmit}>
                      {/* Error alert */}
                      {error && (
                        <Alert variant="danger" style={{ fontSize: '16px', padding: '15px', marginBottom: '20px' }}>
                          {error}
                        </Alert>
                      )}

                      <div className="mb-4">
                        <label className="form-label" htmlFor="formAssignedId" style={{ fontSize: '18px', fontWeight: '500' }}>
                          Assigned ID
                        </label>
                        <input
                          type="text"
                          id="formAssignedId"
                          className="form-control"
                          placeholder="Enter Assigned ID"
                          aria-label="Assigned ID"
                          value={assignedId}
                          onChange={(e) => setAssignedId(e.target.value)}
                          required
                          style={{
                            padding: '14px',
                            fontSize: '18px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                          }}
                        />
                      </div>

                      <div className="mb-4">
                        <label className="form-label" htmlFor="formPassword" style={{ fontSize: '18px', fontWeight: '500' }}>
                          Password
                        </label>
                        <input
                          type="password"
                          id="formPassword"
                          className="form-control"
                          aria-label="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          style={{
                            padding: '14px',
                            fontSize: '18px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                          }}
                        />
                      </div>

                      <div className="text-center pt-1 mb-5 pb-1">
                        <button
                          className="btn btn-primary btn-block mb-3"
                          type="submit"
                          disabled={loading}
                          style={{
                            backgroundColor: '#007bff',
                            borderColor: '#007bff',
                            fontSize: '18px',
                            padding: '14px 28px',
                            width: '100%',
                            borderRadius: '8px',
                          }}
                        >
                          {loading ? 'Logging in...' : 'Log in'}
                        </button>
                      </div>

                      <div className="d-flex align-items-center justify-content-center pb-4">
                        <p className="mb-0 me-2" style={{ fontSize: '16px', color: '#555' }}>
                          Wanna join our mission?
                        </p>
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={handleJoinClick} // Navigate to Home and trigger scroll
                          style={{
                            fontSize: '14px',
                            padding: '8px 16px',
                            borderColor: '#dc3545',
                            borderRadius: '30px',
                            color: '#dc3545',
                            transition: 'all 0.3s ease-in-out',
                          }}
                          onMouseOver={(e) => {
                            e.target.style.backgroundColor = '#dc3545';
                            e.target.style.color = '#fff';
                          }}
                          onMouseOut={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = '#dc3545';
                          }}
                        >
                          Contact Us
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                {/* Right Side (Project Details) */}
                <div className="col-lg-6 d-flex align-items-center bg-primary">
                  <div
                    style={{
                      color: '#fff',
                      padding: '40px',
                      textAlign: 'center',
                    }}
                  >
                    <h4 className="mb-4" style={{ fontSize: '30px', fontWeight: 'bold' }}>Empowering Blind and Visually Impaired Talent</h4>
                    <p style={{ fontSize: '18px', lineHeight: '1.6', color: '#ddd' }}>
                      Clear Horizons is an initiative dedicated to helping blind and visually impaired individuals showcase their unique skills and connect with employers. Our mission is to create an inclusive space where individuals can build profiles, apply for jobs, and find opportunities to grow professionally in an accessible and supportive environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

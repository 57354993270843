import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top w-100">
      <div className="container-fluid">
        <NavLink className="navbar-brand d-flex align-items-center no-pointer-cursor" to="/">
          <img
            src="/clearHorizonsLogo2.png"
            alt="Clear Horizons Logo"
            style={{ width: '80px', height: '80px' }}
          />
          <span className="ms-3 fw-bold fs-4 text-primary">Clear Horizons</span>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink
                className="nav-link fw-bold me-4"
                to="/home"
                style={({ isActive }) => ({
                  textDecoration: 'none', // Remove default underline
                  borderBottom: isActive ? '3px solid blue' : 'none', // Blue underline on active
                })}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fw-bold me-4"
                to="/about"
                style={({ isActive }) => ({
                  textDecoration: 'none', // Remove default underline
                  borderBottom: isActive ? '3px solid blue' : 'none', // Blue underline on active
                })}
              >
                About Us
              </NavLink>
            </li>
            
            <li className="nav-item">
              <NavLink
                className="nav-link fw-bold me-4"
                to="/login"
                style={({ isActive }) => ({
                  textDecoration: 'none', // Remove default underline
                  borderBottom: isActive ? '3px solid blue' : 'none', // Blue underline on active
                })}
              >
                Login
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

// LoadingScreen.js

import React from 'react';
import './LoadingScreen.css';  // Ensure you have a CSS file for styling

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-container">
        <img
          src="/clearHorizonsLogo2.png"
          alt="Clear Horizons Logo"
          className="loading-logo"
        />
        {/* Use the provided <span> element for the text */}
        <span className="ms-3 fw-bold fs-4 text-primary">Clear Horizons</span>
        <div className="loading-bubble">
          <div className="bounce"></div>
          <div className="bounce"></div>
          <div className="bounce"></div>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;

import React, { useState } from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import Modal from './Modal'; // Import the Modal component
import PrivacyModal from './PrivacyPolicyModal'; // Import the Privacy Policy modal component

const Footer = () => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const handleTermsModalOpen = () => {
    setIsTermsModalOpen(true); // Open the Terms and Conditions modal
  };

  const handleTermsModalClose = () => {
    setIsTermsModalOpen(false); // Close the Terms and Conditions modal
  };

  const handlePrivacyModalOpen = () => {
    setIsPrivacyModalOpen(true); // Open the Privacy Policy modal
  };

  const handlePrivacyModalClose = () => {
    setIsPrivacyModalOpen(false); // Close the Privacy Policy modal
  };

  return (
    <footer style={{
      width: '100%',
      backgroundColor: '#e0e0e0', // Light gray background
      color: 'black',
      padding: '20px 40px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}>
      {/* Left Section - Logo and Brand Name */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '20px',
      }}>
        <img
          src={"clearHorizonsLogo2.png"}
          alt="Logo"
          style={{
            height: '100px',
            width: 'auto',
            objectFit: 'contain',
            marginBottom: '2px',
            background: 'transparent',
          }}
        />
        <span style={{
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333', // Dark text color
        }}>
          Clear Horizons
        </span>
      </div>

      {/* Center Section - Contact Us */}
      <div style={{
        textAlign: 'center',
        marginBottom: '20px',
      }}>
        <h4 style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginBottom: '15px',
          color: '#555',
        }}>
          Contact Us
        </h4>
        <p style={{ fontSize: '14px', marginBottom: '8px', color: '#555' }}>
          Email:{'  '}
          <a href="mailto:info@clearhorizons.in" style={{ color: '#555', textDecoration: 'none', marginLeft: '5px' }}>
            info@clearhorizons.in
          </a>
        </p>
        <p style={{ fontSize: '14px', marginBottom: '8px', color: '#555' }}>
          Phone:
          <a href="tel:+919717433853" style={{ color: '#555', textDecoration: 'none', marginLeft: '5px' }}>
            +91 9717433853
          </a>
        </p>
        <p style={{ fontSize: '14px', marginBottom: '8px', color: '#555' }}>
          Phone:
          <a href="tel:+919931512282" style={{ color: '#555', textDecoration: 'none', marginLeft: '5px' }}>
            +91 9931512282
          </a>
        </p>
        <div style={{ marginTop: '15px' }}>
          <a
            href="#"
            onClick={handleTermsModalOpen}  // Open the Terms and Conditions modal on click
            style={{ color: '#555', fontSize: '14px', textDecoration: 'none' }}
            onMouseEnter={(e) => e.target.style.color = '#000'}
            onMouseLeave={(e) => e.target.style.color = '#555'}
          >
            Terms and Conditions
          </a>{' '}|{' '}
          <a
            href="#"
            onClick={handlePrivacyModalOpen}  // Open the Privacy Policy modal on click
            style={{ color: '#555', fontSize: '14px', textDecoration: 'none' }}
            onMouseEnter={(e) => e.target.style.color = '#000'}
            onMouseLeave={(e) => e.target.style.color = '#555'}
          >
            Privacy Policy
          </a>
        </div>
      </div>

      {/* Right Section - Social Media Links */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        marginBottom: '20px',
        flexDirection: 'column', // Stack the "Follow Us" text above the icons
        alignItems: 'center',
      }}>
        <h4 style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginBottom: '1px',
          color: '#555',
        }}>
          Follow Us
        </h4>
        <div style={{
          display: 'flex',
          gap: '15px',
        }}>
          <a
            href="https://www.instagram.com/clearhorizons4/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#555',
              fontSize: '24px',
              textDecoration: 'none',
            }}
            onMouseEnter={(e) => e.target.style.color = '#000'}
            onMouseLeave={(e) => e.target.style.color = '#555'}
          >
            <FaInstagram size={24} />
          </a>
          <a
            href="https://www.linkedin.com/company/clear-horizons1/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#555',
              fontSize: '24px',
              textDecoration: 'none',
            }}
            onMouseEnter={(e) => e.target.style.color = '#000'}
            onMouseLeave={(e) => e.target.style.color = '#555'}
          >
            <FaLinkedin size={24} />
          </a>
        </div>
      </div>

      {/* Modal for Terms and Conditions */}
      <Modal show={isTermsModalOpen} onClose={handleTermsModalClose} />
      
      {/* Modal for Privacy Policy */}
      <PrivacyModal show={isPrivacyModalOpen} onClose={handlePrivacyModalClose} />
    </footer>
  );
};

export default Footer;

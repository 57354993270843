import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function About() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Clear Horizons - About Us";
  })
  // Function to handle the button click and scroll effect
  const handleJoinMission = () => {
    // Navigate to the Home page and pass the state to trigger scroll
    navigate('/home', { state: { scrollToBottom: true } });
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Title Section */}
      <section style={{ textAlign: 'center', padding: '60px 20px' }}>
        <h1 style={{ fontSize: '3.5rem', fontWeight: '700', color: '#333' }}>About Clear Horizons</h1>
        <p style={{ fontSize: '1.5rem', color: '#555', marginTop: '20px', maxWidth: '800px', margin: '20px auto' }}>
          Illuminating paths and redefining possibilities for the visually impaired.
        </p>
      </section>

      {/* Founders Section */}
      <section style={{ display: 'flex', justifyContent: 'center', gap: '60px', marginTop: '40px' }}>
        <div style={{ textAlign: 'center', maxWidth: '250px' }}>
          <img src="/101.jpeg" alt="Ibrahim" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />
          <h3 style={{ fontSize: '1.75rem', fontWeight: '600', marginTop: '15px', color: '#222' }}>Syed Mohammed Ibrahim Ali</h3>
        </div>

        <div style={{ textAlign: 'center', maxWidth: '250px' }}>
          <img src="/102.jpeg" alt="Moaaz" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />
          <h3 style={{ fontSize: '1.75rem', fontWeight: '600', marginTop: '15px', color: '#222' }}>Syed Moaaz Ahmed</h3>
        </div>
      </section>

      {/* Founders Description */}
      <section style={{ marginTop: '40px', textAlign: 'center', fontSize: '1.2rem', color: '#444', maxWidth: '900px', margin: '40px auto' }}>
        <p style={{ lineHeight: '1.75' }}>
          Syed Mohammed Ibrahim Ali and Syed Moaaz Ahmed are the founders behind this initiative. They envisioned Clear Horizons as a platform to bridge the gap between talented blind individuals and employers, empowering them to showcase their skills and secure meaningful employment. Their mission is to create an inclusive future where everyone, regardless of their capabilities, has the opportunity to thrive.
        </p>
      </section>

      {/* Our Motive Section */}
      <section style={{ marginTop: '60px', textAlign: 'center' }}>
        <h2 style={{ fontSize: '2.75rem', fontWeight: '700', color: '#333' }}>Our Motive</h2>
        <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '20px', lineHeight: '1.75', maxWidth: '900px', margin: '20px auto' }}>
          Clear Horizons is more than an initiative—it&apos;s a movement to bridge the gap between the visually impaired and meaningful employment. We believe that every person, regardless of their abilities, deserves the chance to shine in their chosen field. By creating direct channels of communication between employers and blind individuals, we aim to foster inclusivity, equality, and independence.
        </p>
      </section>

      {/* Future Propositions Section */}
      <section style={{ marginTop: '60px', textAlign: 'center' }}>
        <h2 style={{ fontSize: '2.75rem', fontWeight: '700', color: '#333' }}>Future Propositions</h2>
        <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '20px', lineHeight: '1.75', maxWidth: '900px', margin: '20px auto' }}>
          Looking ahead, Clear Horizons envisions a comprehensive <strong>Skill-Up Program</strong>—a groundbreaking initiative designed to elevate the employability of blind individuals. Through specialized training sessions in cutting-edge technology, soft skills, and industry-focused certifications, we aim to transform our candidates into pioneers in their fields. Furthermore, we&apos;re building a vibrant <strong>community network</strong> where collaboration, mentorship, and growth opportunities flourish, ensuring that the impact of our mission grows exponentially.
        </p>
      </section>

      {/* Call to Action Section */}
      <section style={{ marginTop: '60px', textAlign: 'center' }}>
        <p style={{ fontSize: '1.2rem', color: '#444', fontWeight: '300', maxWidth: '800px', margin: '0 auto' }}>
          Together, let&apos;s redefine possibilities and create a world where everyone thrives!
        </p>
        <button style={{
          marginTop: '30px', backgroundColor: '#000', color: '#fff', padding: '15px 30px', fontSize: '1.25rem', borderRadius: '30px', cursor: 'pointer',
          border: 'none', transition: 'background-color 0.3s ease', fontWeight: 'bold'
        }}
          onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#000'}
          onClick={handleJoinMission} // OnClick to navigate and scroll
        >
          Join Our Mission
        </button>
      </section>
    </div>
  );
}

export default About;
